import * as React from "react"
// import Logo from "../icons/logo-2"
import {
  footerStyle,
  copyright,
  blurb,
  // logos,
} from "./footer.module.css"

export function Footer() {
  return (
    <footer className={footerStyle}>
      <div className={blurb}>
        <p>Find Peace</p>
        {/* <div className={logos}>
          <Logo />
        </div> */}
      </div>
      <div className={copyright}>
        Copyright Internal Dialog &copy; {new Date().getFullYear()} · All rights reserved
      </div>
    </footer>
  )
}
